
  .markdown-preview,
  .markdown-preview-var {
    color-scheme: light;
    --color-prettylights-syntax-comment: #6e7781;
    --color-prettylights-syntax-constant: #0550ae;
    --color-prettylights-syntax-entity: #8250df;
    --color-prettylights-syntax-storage-modifier-import: #24292f;
    --color-prettylights-syntax-entity-tag: #116329;
    --color-prettylights-syntax-keyword: #cf222e;
    --color-prettylights-syntax-string: #0a3069;
    --color-prettylights-syntax-variable: #953800;
    --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
    --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
    --color-prettylights-syntax-invalid-illegal-bg: #82071e;
    --color-prettylights-syntax-carriage-return-text: #f6f8fa;
    --color-prettylights-syntax-carriage-return-bg: #cf222e;
    --color-prettylights-syntax-string-regexp: #116329;
    --color-prettylights-syntax-markup-list: #3b2300;
    --color-prettylights-syntax-markup-heading: #0550ae;
    --color-prettylights-syntax-markup-italic: #24292f;
    --color-prettylights-syntax-markup-bold: #24292f;
    --color-prettylights-syntax-markup-deleted-text: #82071e;
    --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
    --color-prettylights-syntax-markup-inserted-text: #116329;
    --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
    --color-prettylights-syntax-markup-changed-text: #953800;
    --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
    --color-prettylights-syntax-markup-ignored-text: #eaeef2;
    --color-prettylights-syntax-markup-ignored-bg: #0550ae;
    --color-prettylights-syntax-meta-diff-range: #8250df;
    --color-prettylights-syntax-brackethighlighter-angle: #57606a;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
    --color-prettylights-syntax-constant-other-reference-link: #0a3069;
    --color-fg-default: #24292f;
    --color-fg-muted: #57606a;
    --color-fg-subtle: #6e7781;
    --color-canvas-default: #ffffff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: hsl(210, 18%, 87%);
    --color-neutral-muted: rgba(175, 184, 193, 0.2);
    --color-accent-fg: #0969da;
    --color-accent-emphasis: #0969da;
    --color-attention-subtle: #fff8c5;
    --color-danger-fg: #cf222e;
  }
[data-color-mode*='light'] .markdown-preview,
[data-color-mode*='light'] .markdown-preview-var,
.markdown-preview-var[data-color-mode*='light'],
.markdown-preview[data-color-mode*='light'],
body[data-color-mode*='light'] {
  color-scheme: light;
  --color-prettylights-syntax-comment: #6e7781;
  --color-prettylights-syntax-constant: #0550ae;
  --color-prettylights-syntax-entity: #8250df;
  --color-prettylights-syntax-storage-modifier-import: #24292f;
  --color-prettylights-syntax-entity-tag: #116329;
  --color-prettylights-syntax-keyword: #cf222e;
  --color-prettylights-syntax-string: #0a3069;
  --color-prettylights-syntax-variable: #953800;
  --color-prettylights-syntax-brackethighlighter-unmatched: #82071e;
  --color-prettylights-syntax-invalid-illegal-text: #f6f8fa;
  --color-prettylights-syntax-invalid-illegal-bg: #82071e;
  --color-prettylights-syntax-carriage-return-text: #f6f8fa;
  --color-prettylights-syntax-carriage-return-bg: #cf222e;
  --color-prettylights-syntax-string-regexp: #116329;
  --color-prettylights-syntax-markup-list: #3b2300;
  --color-prettylights-syntax-markup-heading: #0550ae;
  --color-prettylights-syntax-markup-italic: #24292f;
  --color-prettylights-syntax-markup-bold: #24292f;
  --color-prettylights-syntax-markup-deleted-text: #82071e;
  --color-prettylights-syntax-markup-deleted-bg: #ffebe9;
  --color-prettylights-syntax-markup-inserted-text: #116329;
  --color-prettylights-syntax-markup-inserted-bg: #dafbe1;
  --color-prettylights-syntax-markup-changed-text: #953800;
  --color-prettylights-syntax-markup-changed-bg: #ffd8b5;
  --color-prettylights-syntax-markup-ignored-text: #eaeef2;
  --color-prettylights-syntax-markup-ignored-bg: #0550ae;
  --color-prettylights-syntax-meta-diff-range: #8250df;
  --color-prettylights-syntax-brackethighlighter-angle: #57606a;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #8c959f;
  --color-prettylights-syntax-constant-other-reference-link: #0a3069;
  --color-fg-default: #24292f;
  --color-fg-muted: #57606a;
  --color-fg-subtle: #6e7781;
  --color-canvas-default: #ffffff;
  --color-canvas-subtle: #f6f8fa;
  --color-border-default: #d0d7de;
  --color-border-muted: hsl(210, 18%, 87%);
  --color-neutral-muted: rgba(175, 184, 193, 0.2);
  --color-accent-fg: #0969da;
  --color-accent-emphasis: #0969da;
  --color-attention-subtle: #fff8c5;
  --color-danger-fg: #cf222e;
}
.markdown-preview {
  -webkit-text-size-adjust: 100%;
  font-family: -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  color: var(--color-fg-default);
  background-color: var(--color-canvas-default);
  font-weight: 500;
}
.markdown-preview details,
.markdown-preview figcaption,
.markdown-preview figure {
  display: block;
}
.markdown-preview summary {
  display: list-item;
}
.markdown-preview [hidden] {
  display: none !important;
}
.markdown-preview a {
  background-color: transparent;
  color: var(--color-accent-fg);
  text-decoration: none;
}
.markdown-preview a:active,
.markdown-preview a:hover {
  outline-width: 0;
}
.markdown-preview abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
.markdown-preview b,
.markdown-preview strong {
  font-weight: 600;
}
.markdown-preview dfn {
  font-style: italic;
}
.markdown-preview h1 {
  margin: 0.67em 0;
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid var(--color-border-muted);
}
.markdown-preview mark {
  background-color: var(--color-attention-subtle);
  color: var(--color-text-primary);
}
.markdown-preview small {
  font-size: 90%;
}
.markdown-preview sub,
.markdown-preview sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
.markdown-preview sub {
  bottom: -0.25em;
}
.markdown-preview sup {
  top: -0.5em;
}
.markdown-preview img {
  display: inline-block;
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
  background-color: var(--color-canvas-default);
}
.markdown-preview code,
.markdown-preview kbd,
.markdown-preview pre,
.markdown-preview samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
.markdown-preview figure {
  margin: 1em 40px;
}
.markdown-preview hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid var(--color-border-muted);
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: var(--color-border-default);
}
.markdown-preview input {
  font: inherit;
  margin: 0;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.markdown-preview [type='button'],
.markdown-preview [type='reset'],
.markdown-preview [type='submit'] {
  -webkit-appearance: button;
}
.markdown-preview [type='button']::-moz-focus-inner,
.markdown-preview [type='reset']::-moz-focus-inner,
.markdown-preview [type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
.markdown-preview [type='button']:-moz-focusring,
.markdown-preview [type='reset']:-moz-focusring,
.markdown-preview [type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
.markdown-preview [type='checkbox'],
.markdown-preview [type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
.markdown-preview [type='number']::-webkit-inner-spin-button,
.markdown-preview [type='number']::-webkit-outer-spin-button {
  height: auto;
}
.markdown-preview [type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
.markdown-preview [type='search']::-webkit-search-cancel-button,
.markdown-preview [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
.markdown-preview ::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
.markdown-preview ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.markdown-preview a:hover {
  text-decoration: underline;
}
.markdown-preview hr::before {
  display: table;
  content: '';
}
.markdown-preview hr::after {
  display: table;
  clear: both;
  content: '';
}
.markdown-preview table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: -webkit-max-content;
  width: max-content;
  max-width: 100%;
}
.markdown-preview td,
.markdown-preview th {
  padding: 0;
}
.markdown-preview details summary {
  cursor: pointer;
}
.markdown-preview details:not([open]) > *:not(summary) {
  display: none !important;
}
.markdown-preview kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
}
.markdown-preview h1,
.markdown-preview h2,
.markdown-preview h3,
.markdown-preview h4,
.markdown-preview h5,
.markdown-preview h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.markdown-preview td,
.markdown-preview th {
  padding: 0;
}
.markdown-preview details summary {
  cursor: pointer;
}
.markdown-preview details:not([open]) > *:not(summary) {
  display: none !important;
}
.markdown-preview kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  line-height: 10px;
  color: var(--color-fg-default);
  vertical-align: middle;
  background-color: var(--color-canvas-subtle);
  border: solid 1px var(--color-neutral-muted);
  border-bottom-color: var(--color-neutral-muted);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-neutral-muted);
}
.markdown-preview h1,
.markdown-preview h2,
.markdown-preview h3,
.markdown-preview h4,
.markdown-preview h5,
.markdown-preview h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.markdown-preview h2 {
  font-weight: 600;
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid var(--color-border-muted);
}
.markdown-preview h3 {
  font-weight: 600;
  font-size: 1.25em;
}
.markdown-preview h4 {
  font-weight: 600;
  font-size: 1em;
}
.markdown-preview h5 {
  font-weight: 600;
  font-size: 0.875em;
}
.markdown-preview h6 {
  font-weight: 600;
  font-size: 0.85em;
  color: var(--color-fg-muted);
}
.markdown-preview p {
  margin-top: 0;
  margin-bottom: 10px;
}
.markdown-preview blockquote {
  margin: 0;
  padding: 0 1em;
  color: var(--color-fg-muted);
  border-left: 0.25em solid var(--color-border-default);
}
.markdown-preview ul,
.markdown-preview ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}
.markdown-preview ol ol,
.markdown-preview ul ol {
  list-style-type: lower-roman;
}
.markdown-preview ul ul ol,
.markdown-preview ul ol ol,
.markdown-preview ol ul ol,
.markdown-preview ol ol ol {
  list-style-type: lower-alpha;
}
.markdown-preview dd {
  margin-left: 0;
}
.markdown-preview tt,
.markdown-preview code {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
}
.markdown-preview pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 12px;
  word-wrap: normal;
}
.markdown-preview .octicon {
  display: inline-block;
  overflow: visible !important;
  vertical-align: text-bottom;
  fill: currentColor;
}
.markdown-preview ::placeholder {
  color: var(--color-fg-subtle);
  opacity: 1;
}
.markdown-preview input::-webkit-outer-spin-button,
.markdown-preview input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}
.markdown-preview [data-catalyst] {
  display: block;
}
.markdown-preview::before {
  display: table;
  content: '';
}
.markdown-preview::after {
  display: table;
  clear: both;
  content: '';
}
.markdown-preview > *:first-child {
  margin-top: 0 !important;
}
.markdown-preview > *:last-child {
  margin-bottom: 0 !important;
}
.markdown-preview a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.markdown-preview .absent {
  color: var(--color-danger-fg);
}
.markdown-preview a.anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.markdown-preview .anchor:focus {
  outline: none;
}
.markdown-preview p,
.markdown-preview blockquote,
.markdown-preview ul,
.markdown-preview ol,
.markdown-preview dl,
.markdown-preview table,
.markdown-preview pre,
.markdown-preview details {
  margin-top: 0;
  margin-bottom: 16px;
}
.markdown-preview blockquote > :first-child {
  margin-top: 0;
}
.markdown-preview blockquote > :last-child {
  margin-bottom: 0;
}
.markdown-preview sup > a::before {
  content: '[';
}
.markdown-preview sup > a::after {
  content: ']';
}
.markdown-preview h1 .octicon-link,
.markdown-preview h2 .octicon-link,
.markdown-preview h3 .octicon-link,
.markdown-preview h4 .octicon-link,
.markdown-preview h5 .octicon-link,
.markdown-preview h6 .octicon-link {
  color: var(--color-fg-default);
  vertical-align: middle;
  visibility: hidden;
}
.markdown-preview h1:hover .anchor,
.markdown-preview h2:hover .anchor,
.markdown-preview h3:hover .anchor,
.markdown-preview h4:hover .anchor,
.markdown-preview h5:hover .anchor,
.markdown-preview h6:hover .anchor {
  text-decoration: none;
}
.markdown-preview h1:hover .anchor .octicon-link,
.markdown-preview h2:hover .anchor .octicon-link,
.markdown-preview h3:hover .anchor .octicon-link,
.markdown-preview h4:hover .anchor .octicon-link,
.markdown-preview h5:hover .anchor .octicon-link,
.markdown-preview h6:hover .anchor .octicon-link {
  visibility: visible;
}
.markdown-preview h1 tt,
.markdown-preview h1 code,
.markdown-preview h2 tt,
.markdown-preview h2 code,
.markdown-preview h3 tt,
.markdown-preview h3 code,
.markdown-preview h4 tt,
.markdown-preview h4 code,
.markdown-preview h5 tt,
.markdown-preview h5 code,
.markdown-preview h6 tt,
.markdown-preview h6 code {
  padding: 0 0.2em;
  font-size: inherit;
}
.markdown-preview ul.no-list,
.markdown-preview ol.no-list {
  padding: 0;
  list-style-type: none;
}
.markdown-preview ol[type='1'] {
  list-style-type: decimal;
}
.markdown-preview ol[type='a'] {
  list-style-type: lower-alpha;
}
.markdown-preview ol[type='i'] {
  list-style-type: lower-roman;
}
.markdown-preview div > ol:not([type]) {
  list-style-type: decimal;
}
.markdown-preview ul ul,
.markdown-preview ul ol,
.markdown-preview ol ol,
.markdown-preview ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
.markdown-preview li > p {
  margin-top: 16px;
}
.markdown-preview li + li {
  margin-top: 0.25em;
}
.markdown-preview dl {
  padding: 0;
}
.markdown-preview dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}
.markdown-preview dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.markdown-preview table th {
  font-weight: 600;
}
.markdown-preview table th,
.markdown-preview table td {
  padding: 6px 13px;
  border: 1px solid var(--color-border-default);
}
.markdown-preview table tr {
  background-color: var(--color-canvas-default);
  border-top: 1px solid var(--color-border-muted);
}
.markdown-preview table tr:nth-child(2n) {
  background-color: var(--color-canvas-subtle);
}
.markdown-preview table img {
  background-color: transparent;
}
.markdown-preview img[align='right'] {
  padding-left: 20px;
}
.markdown-preview img[align='left'] {
  padding-right: 20px;
}
.markdown-preview .emoji {
  max-width: none;
  vertical-align: text-top;
  background-color: transparent;
}
.markdown-preview span.frame {
  display: block;
  overflow: hidden;
}
.markdown-preview span.frame > span {
  display: block;
  float: left;
  width: auto;
  padding: 7px;
  margin: 13px 0 0;
  overflow: hidden;
  border: 1px solid var(--color-border-default);
}
.markdown-preview span.frame span img {
  display: block;
  float: left;
}
.markdown-preview span.frame span span {
  display: block;
  padding: 5px 0 0;
  clear: both;
  color: var(--color-fg-default);
}
.markdown-preview span.align-center {
  display: block;
  overflow: hidden;
  clear: both;
}
.markdown-preview span.align-center > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: center;
}
.markdown-preview span.align-center span img {
  margin: 0 auto;
  text-align: center;
}
.markdown-preview span.align-right {
  display: block;
  overflow: hidden;
  clear: both;
}
.markdown-preview span.align-right > span {
  display: block;
  margin: 13px 0 0;
  overflow: hidden;
  text-align: right;
}
.markdown-preview span.align-right span img {
  margin: 0;
  text-align: right;
}
.markdown-preview span.float-left {
  display: block;
  float: left;
  margin-right: 13px;
  overflow: hidden;
}
.markdown-preview span.float-left span {
  margin: 13px 0 0;
}
.markdown-preview span.float-right {
  display: block;
  float: right;
  margin-left: 13px;
  overflow: hidden;
}
.markdown-preview span.float-right > span {
  display: block;
  margin: 13px auto 0;
  overflow: hidden;
  text-align: right;
}
.markdown-preview code,
.markdown-preview tt {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: var(--color-neutral-muted);
  border-radius: 6px;
}
.markdown-preview code br,
.markdown-preview tt br {
  display: none;
}
.markdown-preview del code {
  text-decoration: inherit;
}
.markdown-preview pre code {
  font-size: 100%;
}
.markdown-preview pre > code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.markdown-preview pre {
  font-size: 85%;
  line-height: 1.45;
  background-color: var(--color-canvas-subtle);
  border-radius: 6px;
}
.markdown-preview pre code,
.markdown-preview pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
.markdown-preview pre > code {
  padding: 16px;
  overflow: auto;
  display: block;
}
.markdown-preview .csv-data td,
.markdown-preview .csv-data th {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
  line-height: 1;
  text-align: left;
  white-space: nowrap;
}
.markdown-preview .csv-data .blob-num {
  padding: 10px 8px 9px;
  text-align: right;
  background: var(--color-canvas-default);
  border: 0;
}
.markdown-preview .csv-data tr {
  border-top: 0;
}
.markdown-preview .csv-data th {
  font-weight: 600;
  background: var(--color-canvas-subtle);
  border-top: 0;
}
.markdown-preview .footnotes {
  font-size: 12px;
  color: var(--color-fg-muted);
  border-top: 1px solid var(--color-border-default);
}
.markdown-preview .footnotes ol {
  padding-left: 16px;
}
.markdown-preview .footnotes li {
  position: relative;
}
.markdown-preview .footnotes li:target::before {
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -24px;
  pointer-events: none;
  content: '';
  border: 2px solid var(--color-accent-emphasis);
  border-radius: 6px;
}
.markdown-preview .footnotes li:target {
  color: var(--color-fg-default);
}
.markdown-preview .footnotes .data-footnote-backref g-emoji {
  font-family: monospace;
}
.markdown-preview .task-list-item {
  list-style-type: none;
}
.markdown-preview .task-list-item label {
  font-weight: 400;
}
.markdown-preview .task-list-item.enabled label {
  cursor: pointer;
}
.markdown-preview .task-list-item + .markdown-preview .task-list-item {
  margin-top: 3px;
}
.markdown-preview .task-list-item .handle {
  display: none;
}
.markdown-preview .task-list-item-checkbox,
.markdown-preview .contains-task-list input[type='checkbox'] {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
}
.markdown-preview .contains-task-list:dir(rtl) .task-list-item-checkbox,
.markdown-preview .contains-task-list:dir(rtl) input[type='checkbox'] {
  margin: 0 -1.6em 0.25em 0.2em;
}
.markdown-preview ::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
.markdown-preview pre {
  position: relative;
}
.markdown-preview pre .copied {
  visibility: hidden;
  display: flex;
  position: absolute;
  cursor: pointer;
  color: var(--color-fg-defaul);
  top: 6px;
  right: 6px;
  border-radius: 5px;
  background: var(--color-border-default);
  padding: 6px;
  font-size: 12px;
  transition: all 0.3s;
}
.markdown-preview pre .copied .octicon-copy {
  display: block;
}
.markdown-preview pre .copied .octicon-check {
  display: none;
}
.markdown-preview pre:hover .copied {
  visibility: visible;
}
.markdown-preview pre:hover .copied:hover {
  background: var(--color-prettylights-syntax-entity-tag);
  color: var(--color-canvas-default);
}
.markdown-preview pre:hover .copied:active,
.markdown-preview pre .copied.active {
  background: #2e9b33;
  color: var(--color-canvas-default);
}
.markdown-preview pre .active .octicon-copy {
  display: none;
}
.markdown-preview pre .active .octicon-check {
  display: block;
}
.highlight-line {
  background-color: var(--color-neutral-muted);
}
.code-line.line-number::before {
  display: inline-block;
  width: 1rem;
  text-align: right;
  margin-right: 16px;
  color: var(--color-fg-subtle);
  content: attr(line);
  white-space: nowrap;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: var(--color-prettylights-syntax-comment);
}
.token.namespace {
  opacity: 0.7;
}
.token.property,
.token.tag,
.token.selector,
.token.constant,
.token.symbol,
.token.deleted {
  color: var(--color-prettylights-syntax-entity-tag);
}
.token.maybe-class-name {
  color: var(--color-prettylights-syntax-variable);
}
.token.property-access,
.token.operator,
.token.boolean,
.token.number,
.token.selector .token.class,
.token.attr-name,
.token.string,
.token.char,
.token.builtin {
  color: var(--color-prettylights-syntax-constant);
}
.token.deleted {
  color: var(--color-prettylights-syntax-markup-deleted-text);
}
.code-line .token.deleted {
  background-color: var(--color-prettylights-syntax-markup-deleted-bg);
}
.token.inserted {
  color: var(--color-prettylights-syntax-markup-inserted-text);
}
.code-line .token.inserted {
  background-color: var(--color-prettylights-syntax-markup-inserted-bg);
}
.token.variable {
  color: var(--color-prettylights-syntax-constant);
}
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: var(--color-prettylights-syntax-string);
}
.token.color,
.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: var(--color-prettylights-syntax-string);
}
.token.rule,
.token.regex,
.token.important,
.token.keyword {
  color: var(--color-prettylights-syntax-keyword);
}
.token.coord {
  color: var(--color-prettylights-syntax-meta-diff-range);
}
.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
