/* Input style */
.input_container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input_container:focus-within label {
  /* transform: translate(0, 12px) scale(0.8); */
  color: #030303;
}

.input_container .filled {
  /* transform: translate(0, 12px) scale(0.8); */
}

.input_container label {
  text-align: start;
  padding-left: 5px;
  /* position: absolute; */
  pointer-events: none;
  /* transform: translate(0, 26px) scale(1); */
  transform-origin: top left;
  /* transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
  color: #030303;
  opacity: 0.8;
  font-size: 20px;
  font-weight: 500;
  /* line-height: 1; */
  /* left: 12px; */
}

.input_container input {
  height: 100%;
  border-radius: 8px;
  border: none;
  /* padding: 16px 12px 4px 16px; */
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input_container input:focus {
  box-shadow: 0 0 0 1px #6b7b87;
}
