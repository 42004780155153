@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.w-md-editor-text {
  height: 100%;
}
.wmde-markdown {
  background-color: unset;
  color: #000;
}

.wmde-markdown pre code, .wmde-markdown pre tt {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  background-color:#f0ebeb;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background-color:#f0ebeb;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #5555e8;
  border-radius: 10px !important;
}

/* Hover styles for scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #5555e8;
}

/* Customize the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
