/*
  Based on TailwindCSS recommendations,
  consider using classes instead of the `@apply` directive
  @see https://tailwindcss.com/docs/reusing-styles#avoiding-premature-abstraction
*/
.page {
  @apply w-full h-full shadow-[0_0.5rem_1rem_rgba(0,0,0,0.45)] mt-2.5 p-[5px] rounded-[10px];
}

/* Chat */
.chat {
  @apply h-[calc(100%_-_69px)];
}
.chat-container {
  @apply h-full;
}

/* Conversation */
.conversation {
  @apply h-[calc(100%_-_12px)] relative z-0;
  background: #fff;
}
.conversation ::-webkit-scrollbar {
  @apply transition-all duration-[0.5s] w-[5px] h-px z-10;
}
.conversation ::-webkit-scrollbar-track {
  background: transparent;
}
.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}
.conversation .conversation-container {
  @apply h-[calc(100%_-_68px)] max-h-[60vh] overflow-auto mb-[5px] px-4 py-0 after:content-[''] after:table after:clear-both;
}

/* Messages */
.message {
  @apply text-black clear-both leading-[18px] text-[15px] relative max-w-[85%] mx-0 my-2 p-2 after:absolute after:content-[''] after:w-0 after:h-0 after:border-solid first:mt-4 first:mb-2 first:mx-0;
  word-wrap: break-word;
}
.metadata {
  @apply flex gap-[5px] float-right relative pl-[7px] pr-0 py-0 -bottom-1;
}
.metadata .time {
  @apply text-[rgba(0,0,0,0.45)] text-[11px] inline-block;
}
.message.received {
  @apply float-left rounded-[0px_5px_5px_5px] after:left-[-9px] after:border-[0px_10px_10px_0] after:border-[transparent_#f2f3f8_transparent_transparent] after:top-0;
  background: #f2f3f8;
}
.message.received .metadata {
  @apply pl-4 pr-0 py-0;
}
.message.sent {
  @apply flex flex-col text-white float-right text-[13px] rounded-[5px_0px_5px_5px] after:right-[-9px] after:border-[0px_0_10px_10px] after:border-[transparent_transparent_transparent_#82a1f8] after:top-0;
  background: #82a1f8;
}

/* Compose */
.conversation-compose {
  @apply flex flex-col items-end overflow-hidden w-full items-center;
  background: white;
}
.conversation-compose div,
.conversation-compose input {
  @apply h-full;
  background: #fff;
}
.conversation-compose .input-msg {
  @apply border flex-auto text-sm min-w-[50px] m-0 border-solid border-[#ddd6d6];
  outline: none;
}
.send-button {
  @apply h-[30px] w-[65px] text-white m-1 rounded-[25px];
  background: #81be0a;
}

/* Small Screens */
@media (max-width: 768px) {
  .conversation {
    @apply h-[calc(100vh_-_55px)];
  }
  .conversation .conversation-container {
    @apply h-[calc(100vh_-_120px)];
  }
}
