.markdown-preview ul, menu {
  list-style: inherit !important;
  margin: 0 !important;
  padding: revert !important;
}

.markdown-preview ol {
  list-style: auto !important;
  margin: 0 !important;
  padding: revert !important;
}

.markdown-preview a {
  color: blue;
}

.markdown-preview table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
}

.markdown-preview table tr {
  border-top: 1px solid #30363d;
}

.markdown-preview table th, .markdown-preview table td {
  padding: 6px 13px;
  border: 1px solid #30363d;
}

.markdown-preview table th, .markdown-preview table td {
  padding: 6px 13px;
  border: 1px solid #30363d;
}

.w-md-editor-preview ul, menu {
  list-style: inherit !important;
  margin: 0 !important;
  padding: revert !important;
}

.w-md-editor-preview ol {
  list-style: auto !important;
  margin: 0 !important;
  padding: revert !important;
}

.w-md-editor-preview a {
  color: blue;
}

.wmde-markdown table tr {
  background-color: unset !important;
}

.wmde-markdown table tr:nth-child(2n) {
  background-color: unset !important;
}